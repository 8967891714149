























































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import Customer from '@/models/Customer';
import User from '@/models/User';
import Location from '@/models/Location';
import {namespace} from 'vuex-class';
import JobsFilterData from '@/misc/JobsFilterData';
import Job from '@/models/Job';
import {Permission} from '@/misc/enums/permission.enum';
import {KeyOf} from '@/misc/Parseable';
import {RequestParams} from '@/interfaces/Responses';

const CustomerStore = namespace('customer');
const UserStore = namespace('user');
const JobStore = namespace('job');

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    UserInitialsComponent: () => import(
      /* webpackChunkName: "UserInitialsComponent" */
      '@/components/user/UserInitials.component.vue'),
    RJAutocomplete: () => import(
      '@/components/shared/custom-vuetify/RJAutocomplete.vue'),
  },
})
export default class JobFilterComponent extends Vue {

  @Prop({default: () => new JobsFilterData()})
  public filterData!: JobsFilterData;
  public selectedCustomer: Customer | null = null;
  public selectedUsers: User[] = [];
  public selectedManager: User[] = [];
  public selectedLocations: Location[] = [];
  public selectedJobStatus: string[] = [];
  private jobStatus: string[] = [
    'DONE',
    'IN_PROGRESS',
    'NOT_TOUCHED',
    'IN_FUTURE',
    'NOT_PLANNED'];

  @UserStore.Action('loadUsersAction')
  private loadUsersAction!: (payload: { companyId: string, populate: KeyOf<User>[] }) => Promise<User[]>;
  @CustomerStore.Action('loadCustomersAction')
  private loadCustomersAction!: (companyId: string) => Promise<Customer[]>;
  @CustomerStore.Action('loadLocationsAction')
  private loadLocationsAction!: (payload: RequestParams<Location>) => Promise<Location[]>;
  @CustomerStore.Action('loadCustomerAction')
  private loadCustomerAction!: (customerId: string) => Promise<Customer>;
  @CustomerStore.Getter('customer')
  private _customer!: Customer;
  @CustomerStore.Getter('locations')
  private _locations!: Location[];
  @CustomerStore.Mutation('storeCustomer')
  private storeCustomer!: (customer?: Customer) => void;

  public get manager(): User[] {
    return this._users ? this._users
      .filter((user) => (user.role!.isTenantAdmin ||
        user.role?.canBeLocationManager) && user.active) : [];
  }

  get locations(): Location[] {
    return this.$userRoleHandler.hasPermission(Permission.CUSTOMER_READ_OWN) ? this._customer ? this._customer.locations : [] : this._locations ?? [];
  }

  @UserStore.Getter('users')
  private _users!: User[];

  public get users(): User[] {
    return this._users ? this._users
      .filter((user) => user.role!.hasPermission(Permission.WORKSESSION_READ_OWN)) : [];
  }

  @CustomerStore.Getter('customers')
  private _customers!: Customer[];

  public get customers(): Customer[] {
    return this._customers ? this._customers : [];
  }

  @JobStore.Getter('jobs')
  private _jobs!: Job[];

  get jobs(): Job[] {
    return this._jobs;
  }

  public _groupBy: 'none' | 'customer' | 'employee' = 'none';

  // Getter and Setter for GroupBy instead of watch
  private get groupBy() {
    return this._groupBy;
  }

  private set groupBy(value: 'none' | 'customer' | 'employee') {
    this.$emit('change-group', value);
    this._groupBy = value;
  }

  public getUserId(user: User): string {
    return user.id!;
  }

  public async created() {
    try {
      // load all data that is required for this view
      if (this.$userRoleHandler.hasPermission(Permission.CUSTOMER_READ_OWN)) {
        await this.loadCustomersAction(this.$route.params.companyId);
      } else {
        await this.loadLocationsAction({companyId: this.$route.params.companyId});
      }
      await this.loadUsersAction({companyId: this.$route.params.companyId, populate: ['role']});

      // rest customer data that is possible available
      this.storeCustomer(undefined);

      // process url filter values
      this.selectedUsers = this.users.filter((user: User) =>
        this.filterData.users!.indexOf(user.id!) !== -1);
      this.selectedManager = this.manager.filter((manager: User) =>
        this.filterData.managers!.indexOf(manager.id!) !== -1);

      if (this.filterData.customer) {
        this.selectedCustomer = this.customers
          .find((customer: Customer) => customer.id === this.filterData.customer)!;
        await this.onCustomerSelect();
      }
    } catch (e: any) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }

  public async onCustomerSelect() {
    if (this.selectedCustomer) {
      try {
        if (this.$userRoleHandler.hasPermission(Permission.CUSTOMER_READ_OWN)) {
          await this.loadCustomerAction(this.selectedCustomer!.id as string);
        }
        // process url filter values
        if (this.filterData.locations!.length > 0) {
          this.selectedLocations = this._customer.locations
            .filter((location: Location) => this.filterData.locations!.indexOf(location.id!) !== -1);
        }
      } catch (e: any) {
        this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
      }
    } else {
      this.selectedLocations = [];
      this.storeCustomer(undefined);
    }
    this.onMainFilterChange();
  }

  /**
   * Notifies parent component about filter change
   */
  public onMainFilterChange() {
    const filterData: Partial<JobsFilterData> = {
      customer: this.selectedCustomer ? this.selectedCustomer.id : undefined,
      users: this.selectedUsers.map((item) => item.id) as string[],
      managers: this.selectedManager.map((item) => item.id) as string[],
      locations: this.selectedLocations.map((item) => item.id) as string[],
    };
    this.$emit('main-filter-change', filterData);
  }

  private resetFilter() {
    this.selectedCustomer = null;
    this.selectedLocations = [];
    this.selectedManager = [];
    this.selectedUsers = [];
    this.selectedJobStatus = [];
    this.onMainFilterChange();
  }

  /**
   * Support function to get user name
   * @param user
   */
  public getUserFullName(user: User): string {
    return user.fullName;
  }

  public onJobStatusChange(): void {
    this.$emit('job-status-change', this.selectedJobStatus);
  }
}
