var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"flex-column justify-center align-self-start custom-border filter-background px-4 py-5",scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"overflow-handle"},[_vm._v(_vm._s(item.fullName))])]}}])},[_c('div',{staticClass:"mb-3 d-flex filter-header subheader--text font-weight-medium"},[_c('span',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$t('JOB_OVERVIEW.MAIN_FILTER_TITLE'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',{staticClass:"ml-auto"},[_c('v-btn',_vm._g({attrs:{"icon":"","small":"","text":""},on:{"click":function($event){return _vm.resetFilter()}}},on),[_c('v-icon',{attrs:{"color":"subheader"}},[_vm._v(" mdi-reload ")])],1)],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('JOB_OVERVIEW.RESET_FILTER'))+" ")])],1),_c('span',{staticClass:"mb-2 subheader--text font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.OBJECT_MANAGER'))+" ")]),(_vm.$userRoleHandler.hasPermission(_vm.Permission.CUSTOMER_UPDATE_OWN))?_c('RJAutocomplete',{staticClass:"mb-4",attrs:{"clearable":true,"item-text":_vm.getUserFullName,"item-value":_vm.getUserId,"items":_vm.manager,"multiple":true,"return-object":true},on:{"change":function($event){return _vm.onMainFilterChange()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"overflow-handle"},[_vm._v(_vm._s(item.fullName))])]}}],null,false,316517011),model:{value:(_vm.selectedManager),callback:function ($$v) {_vm.selectedManager=$$v},expression:"selectedManager"}}):_vm._e(),_c('span',{staticClass:"mb-2 subheader--text font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.EMPLOYEE'))+" ")]),_c('RJAutocomplete',{staticClass:"mb-4",attrs:{"clearable":true,"item-text":_vm.getUserFullName,"item-value":_vm.getUserId,"items":_vm.users,"multiple":true,"return-object":true},on:{"change":function($event){return _vm.onMainFilterChange()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"overflow-handle"},[_vm._v(_vm._s(item.fullName))])]}}]),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}}),_c('span',{staticClass:"mb-2 subheader--text font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.CUSTOMER'))+" ")]),_c('RJAutocomplete',{staticClass:"mb-4",attrs:{"clearable":true,"items":_vm.customers,"return-object":true,"item-text":"name"},on:{"change":function($event){return _vm.onCustomerSelect()},"click:clear":function($event){return _vm.onMainFilterChange()}},model:{value:(_vm.selectedCustomer),callback:function ($$v) {_vm.selectedCustomer=$$v},expression:"selectedCustomer"}}),_c('span',{staticClass:"mb-2 subheader--text font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.LOCATION'))+" ")]),_c('RJAutocomplete',{staticClass:"mb-4",attrs:{"clearable":true,"disabled":_vm.locations.length <= 0,"items":_vm.locations,"multiple":true,"return-object":true,"item-text":"name"},on:{"change":function($event){return _vm.onMainFilterChange()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.selectedLocations),callback:function ($$v) {_vm.selectedLocations=$$v},expression:"selectedLocations"}}),_c('span',{staticClass:"mb-2 subheader--text font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.STATUS'))+" ")]),_c('RJAutocomplete',{staticClass:"mb-4",attrs:{"clearable":true,"disabled":_vm.jobStatus.length <= 0,"items":_vm.jobStatus,"multiple":true,"return-object":true,"chip-content":function (item) { return this$1.$i18n.t("JOB_OVERVIEW.LEGEND.CALENDAR_EVENT."  + item); }},on:{"change":function($event){return _vm.onJobStatusChange()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t("JOB_OVERVIEW.LEGEND.CALENDAR_EVENT." + item))+" ")])]}}]),model:{value:(_vm.selectedJobStatus),callback:function ($$v) {_vm.selectedJobStatus=$$v},expression:"selectedJobStatus"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }